import React from "react";

import clsx from "clsx";

import * as styles from "./styles.css";

import type { ReactNode } from "react";

export interface RichTextStyledProps {
  class?: string;
}

export function RichTextStyled(
  children: ReactNode,
  props: RichTextStyledProps
) {
  const className = clsx({
    [styles.extraLarge]: props.class === "xl",
    [styles.extraLarge]: props.class === "standfirst",
    [styles.h3]: props.class === "h3",
  });

  return <span className={className}>{children}</span>;
}
